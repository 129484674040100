<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { formatDate } from '~/utils/formatter'

const props = defineProps<{
  userIds: string[]
  updateDate: string
  hideFullName: boolean
  inDrawerHeader: boolean
  displayAmount: boolean
  isEnableModal: boolean
  isRelative: {
    type: boolean
    required: false
    default: true
  }
  isLastLine: {
    type: boolean
    required: false
    default: false
  }
}>()

const state = reactive({
  isRelative: props.isRelative !== undefined,
})
</script>

<template>
  <div class="cursor-pointer">
    <Popover :class="`h-6 ${state.isRelative && 'relative'}`">
      <PopoverButton class="inline-flex items-center space-x-2 text-primary">
        <div
          class="flex items-center -space-x-2 overflow-hidden"
          data-cy="avatar-multiple-container"
        >
          <Avatar
            v-for="userId in userIds"
            :key="userId"
            class="inline-block"
            :user-id="userId"
            :record-date="new Date(props.updateDate)"
            multiple
            :fullname="!hideFullName && userIds.length === 1"
          />
        </div>
        <span
          v-if="userIds.length > 1 && displayAmount"
          class="align-center text-sm"
        >
          {{ $t('global.people', { amount: userIds.length }) }}
        </span>
      </PopoverButton>
      <PopoverPanel
        v-if="userIds.length > 1 || props.isEnableModal"
        class="z-10 flex w-max select-none flex-col rounded-lg border border-gray-600 bg-white"
        :class="[
          props.isLastLine
            ? 'fixed'
            : `absolute ${props.isEnableModal && '-bottom-0.5 right-6'}`,
        ]"
        :style="{ bottom: props.isLastLine ? `108px` : '' }"
      >
        <div class="flex flex-col items-center">
          <Avatar
            v-for="userId in userIds"
            :key="userId"
            :user-id="userId"
            class="px-2 py-1 hover:rounded-lg hover:bg-gray-100"
            multiple
            fullname
          />
          <span
            v-if="props.updateDate"
            class="text-center text-sm text-gray-600"
            >{{ formatDate(props.updateDate) }}</span
          >
        </div>
      </PopoverPanel>
    </Popover>
  </div>
</template>
