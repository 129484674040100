import * as _ from 'lodash'
import { addHours } from 'date-fns'
import { i18n } from '~/modules/i18n'
import type { JStep, StepBranching } from '~/models/documents/jStep'
import {
  SpecialStepBranchingValues,
  StepType,
  StepTypesWithBranching,
} from '~/models/documents/jStep'
import CellRendererCheckbox from '~/components/UI/CellRendererCheckbox.vue'
import CellRendererRadio from '~/components/UI/CellRendererRadio.vue'
import CellRendererSelect from '~/components/UI/CellRendererSelect.vue'
import CellRendererTime from '~/components/UI/CellRendererTime.vue'
import CellRendererString from '~/components/UI/CellRendererString.vue'
import CustomHeader from '~/components/UI/CustomHeader.vue'
import CellRendererID from '~/components/UI/CellRendererID.vue'
import CellRendererPhoto from '~/components/UI/CellRendererPhoto.vue'
import {
  DocumentSettingsType,
  RepetitionType,
} from '~/models/documents/documentSettings'
import { parseArrayObjectToKeyAndLabel } from '~/utils/collections'
import { isUndefinedOrNullOrEmpty } from '~/utils/object'
import { usersStore } from '~/store/users'
import CellRendererNameDescriptionDetail from '~/components/UI/CellRendererNameDescriptionDetail.vue'
import NotificationController from '~/controllers/notifications'
import { NotificationType } from '~/models/notifications/jNotification'
import NumericCellEditor from '~/components/Classes/NumericCellEditor'
import { ColumnState } from '~/utils/report'
import {
  isDoubleCheckValid,
  validateMandatoryStepFilled,
  validateStepBorderLine,
  validateStepKo,
} from '~/controllers/documents'
import { ReportInputDataViewModel } from '~/viewModels/reportAnswerViewModel'
import type { ContextScopeViewModel } from '~/viewModels/session/contextScopeViewModel'
import {
  NotificationMode,
  NotificationState,
} from '~/common/models/notification'
import type { AlertViewModel } from '~/common/models/alerts'
import TextAreaCellEditor from '~/components/Classes/TextAreaCellEditor'
import type { JReport } from '~/models/documents/jReport'
import { ReportStatus, RoleGrid } from '~/models/documents/jReport'
import { documentSettingsStore } from '~/store/documentSettings'
import { JInputData, JInputDataContext } from '~/models/report/jInputData'
import { settingsStore } from '~/store/settings'
import { SettingsType } from '~/models/settings/settings'
import { gridStore } from '~/store/grid'
import CellRendererCalculator from '~/components/UI/CellRendererCalculator.vue'
import { PDF_CELL_WIDTH } from '~/models/Style'
import {
  addFrequencyTime,
  getReportStartingTime,
  getTimeFrequencyDate,
  nextDate,
} from '~/helpers/FrequencyHelper'
import { Frequency } from '~/models/documents/jDocument'
import api from '~/helpers/ApiHelper'
import { siteStore } from '~/store/site'

const { t } = i18n.global

export const NORMAL_NAME_COLUMN_WITH = 450
export const EXPORT_NAME_COLUMN_WITH = 200

export const DISABLED_ROW_BACKGROUND_COLOR = { 'background-color': '#F4F4F4' }

export const isNewColumn = (steps: any[], indexCol: number) => {
  for (const step of steps) {
    if (!isUndefinedOrNullOrEmpty(step.answers?.[indexCol]?.value)) {
      if (step.type === StepType.Checkbox) {
        if (step.answers?.[indexCol]?.value !== false) return false
        else continue
      }
      return false
    }
  }
  return true
}

export const isNewCol = (inputData: JInputData[], indexCol: number) => {
  return !inputData?.some((data) => data.col_id === indexCol)
}

export const isColFullNA = (steps: any[], indexCol: number) => {
  for (const step of steps) {
    if (step.last_sampling_areas[indexCol] === true) return false
  }
  return true
}

const hasColValidValues = (
  steps: any[],
  inputData: JInputData[],
  indexCol: number,
) => {
  for (const step of steps) {
    if (
      checkIsCellDisabled(
        step.col_ids_to_disable,
        indexCol,
        step.parentFrequency,
        step.disabled,
      )
    )
      continue
    const answer = inputData?.find(
      (i) => i?.row_id === step.num_step - 1 && i?.col_id === indexCol,
    )
    if (answer && validateStepKo(answer.value, step, indexCol, false, answer))
      return false // Return false immediately if an invalid case is found
  }
  return true // Return true if no invalid cases are found
}

export const areAllRowsFilled = (
  steps: any[],
  inputData: JInputData[],
  indexCol: number,
) => {
  let cellsToFill = 0
  let cellsFilled = 0

  steps?.forEach((step: JStep) => {
    if (
      step.is_mandatory &&
      step.last_sampling_areas?.[indexCol] &&
      !step.hidden
    ) {
      if (
        checkIsCellDisabled(
          step.col_ids_to_disable,
          indexCol,
          step.parentFrequency,
          step.disabled,
        )
      )
        return
      cellsToFill++
    }
  })
  for (const step of steps) {
    if (step.is_mandatory && step.last_sampling_areas?.[indexCol]) {
      if (
        checkIsCellDisabled(
          step.col_ids_to_disable,
          indexCol,
          step.parentFrequency,
          step.disabled,
        )
      )
        continue
      const answer = inputData?.find(
        (i) => i.row_id === step.num_step - 1 && i.col_id === indexCol,
      )
      if (answer !== undefined && answer.value !== null) cellsFilled++
    }
  }
  return cellsFilled === cellsToFill
}

export const checkIsCellDisabled = (
  colIdsToDisable: number[],
  colIndex: number,
  parentFrequency: number,
  isDisabled: boolean,
) => {
  if (colIdsToDisable?.includes(colIndex) || isDisabled) return true

  return false
}

export const checkMandatoryCellsNotFilled = (report: any) => {
  const erroredCells = [] as Array<{ row_id: number; col_id: number }>
  const numberOfColumns = report.gridSize

  const steps = report.steps
  for (const [rowIndex, step] of steps.entries()) {
    if (!step.is_mandatory || step.hidden) continue

    for (let i = 1; i <= numberOfColumns; i++) {
      const colIndex = i - 1
      if (!step.last_sampling_areas[colIndex]) continue

      if (
        checkIsCellDisabled(
          step.col_ids_to_disable,
          colIndex,
          step.parentFrequency,
          step.disabled,
        )
      )
        continue

      const lastInputData = report.inputData
        ?.sort((inputA: JInputData, inputB: JInputData) => {
          return inputA.update_date > inputB.update_date
        })
        ?.find((i) => i.col_id === colIndex && i.row_id === rowIndex)

      if (validateMandatoryStepFilled(lastInputData?.value, true, true))
        erroredCells.push({ row_id: step?.num_step - 1, col_id: colIndex })
      else if (isDoubleCheckValid(step, lastInputData))
        erroredCells.push({ row_id: step?.num_step - 1, col_id: colIndex })
    }
  }

  const grouped = _.groupBy(erroredCells, 'col_id')
  return Object.keys(grouped).map((col_id) => ({
    col_id: Number.parseInt(col_id),
    rows: grouped[col_id].map((obj) => obj.row_id),
  }))
}

export const getGridHeader = (providedHeader: string) => {
  return `${providedHeader || t('report.control')}`
}

export const getInsightGridHeader = (providedHeader: string) => {
  return Array.isArray(providedHeader) || !providedHeader
    ? t('report.valid_controls')
    : t('report.custom_valid_controls', { header: providedHeader })
}

export const getColumnState = (
  steps: any[],
  inputData: JInputData[],
  index: number,
  isCurrentColumn = true,
  maxIndexUsed: number,
  disabled = false,
  role = RoleGrid.editor,
  isHistory = false,
) => {
  const indexCol = index

  if (isNewCol(inputData, indexCol)) {
    if (indexCol < maxIndexUsed && role !== RoleGrid.preview)
      return isColFullNA(steps, indexCol) ? ColumnState.ok : ColumnState.ko
    return ColumnState.new
  }
  if (areAllRowsFilled(steps, inputData, indexCol)) {
    if (hasColValidValues(steps, inputData, indexCol)) return ColumnState.ok
    else return ColumnState.ko
  } else {
    return isCurrentColumn &&
      hasColValidValues(steps, inputData, indexCol) &&
      !disabled &&
      !isHistory
      ? ColumnState.inProgress
      : ColumnState.ko
  }
}

export const computeColumnsInsights = (
  allInputData: ReportInputDataViewModel[],
  indexCol: number,
  steps: JStep[],
  isLastColumn = false,
) => {
  let colValidCells = 0
  let colControlKo = 0
  let columnState = ColumnState.ok

  for (let indexRow = 0; indexRow < steps.length; indexRow++) {
    const step = steps[indexRow]
    const dataCell = _.findLast(
      allInputData,
      (data) => data.col_id === indexCol && data.row_id === indexRow,
    )
    const isValid = !validateStepKo(
      dataCell?.value,
      steps[indexRow],
      indexCol,
      !isLastColumn,
      dataCell,
    )

    if (dataCell) {
      if (
        !isValid &&
        !checkIsCellDisabled(
          step.col_ids_to_disable,
          indexCol,
          step.parentFrequency,
          step.disabled,
        ) &&
        !step.hidden
      ) {
        colControlKo++
        columnState = ColumnState.ko
      } else {
        colValidCells++
        if (columnState !== ColumnState.ko) columnState = ColumnState.ok
      }
    } else if (
      (isUndefinedOrNullOrEmpty(dataCell?.value) && isLastColumn) ||
      step.hidden
    ) {
      colValidCells++
      if (columnState !== ColumnState.ko) columnState = ColumnState.ok
    } else if (
      step?.last_sampling_areas &&
      step?.last_sampling_areas[indexCol] &&
      step?.is_mandatory
    ) {
      colControlKo++
      columnState = ColumnState.ko
    } else if (!step?.last_sampling_areas) {
      colControlKo++
      columnState = ColumnState.ko
    }
  }
  return {
    columnState,
    colValidCells,
    colControlTotal: colControlKo + colValidCells,
  }
}

export const computeStepsInsights = (
  allInputData: ReportInputDataViewModel[],
  maxIndexUsed: number,
  steps: JStep[],
) => {
  let validSteps = steps.length

  for (let indexRow = 0; indexRow < steps.length; indexRow++) {
    let isStepKo = false
    for (let indexCol = 0; indexCol <= maxIndexUsed; indexCol++) {
      const dataCell = _.findLast(
        allInputData,
        (data) => data.col_id === indexCol && data.row_id === indexRow,
      )
      const isValid = !validateStepKo(
        dataCell?.value,
        steps[indexRow],
        indexCol,
        true,
        dataCell,
      )

      if (dataCell && !isValid) isStepKo = true
      else if (
        steps[indexRow]?.last_sampling_areas &&
        steps[indexRow].last_sampling_areas[indexCol] &&
        !isValid &&
        steps[indexRow].is_mandatory
      )
        isStepKo = true
      else if (!steps[indexRow]?.last_sampling_areas) isStepKo = true
    }
    isStepKo && validSteps--
  }
  return validSteps
}

export const getUpdatedData = (
  steps: any[],
  inputData: JInputData[],
  event: any,
  shiftColumnIndex: number,
  report_id: string,
  reason: string,
  isValid?: boolean,
) => {
  const columnState = getColumnState(
    steps,
    inputData,
    event.colDef.index - shiftColumnIndex,
  )
  const currentUserId = usersStore().user.id
  let value = event.value
  const context = {} as JInputDataContext

  if (steps[event.rowIndex]?.type === StepType.Calculator) {
    value = event.value?.result
    context.calculContext = event.value?.calculContext
  }

  return new ReportInputDataViewModel({
    value: value,
    col_id: event.colDef.index - shiftColumnIndex,
    row_id: event.rowIndex,
    updated_by: currentUserId,
    update_date: new Date().getTime(),
    columnState,
    step_id: steps[event.rowIndex]?.id,
    type: steps[event.rowIndex]?.type,
    report_id,
    reason,
    is_valid: isValid,
    context,
  })
}

export const getSeparator = (
  locale: string,
  separatorType: string,
): string | undefined => {
  if (!locale) locale = 'fr'

  const numberWithGroupAndDecimalSeparator = 1000.1
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithGroupAndDecimalSeparator)
    .find((part) => part.type === separatorType)?.value
}

export const handleTags = (allTags: any[], currentTags: any[]) => {
  return (
    currentTags
      ?.map((tagId: string) => {
        const foundTag = allTags.find((e) => e.id === tagId)
        if (foundTag) {
          return { value: foundTag.value, bgColor: foundTag.bgColor }
        }
        return null
      })
      .filter((tag) => tag !== null) || []
  )
}

export const setDetailsByType = (
  step: any,
  samplingAreas: any[],
  shiftColumnIndex: number,
  updatedShiftIndex: number,
  listOptions: any[],
  computeSampling: boolean,
) => {
  let details: any = {}

  if (
    step.type === StepType.Measure ||
    (step.type === StepType.Calculator && step.is_measure)
  ) {
    details = {
      goal: step.goal,
      unit: step.unit,
      extent: step.extent,
      range: step.range,
      means_of_measure: step.means_of_measure,
      last_targets: step.last_targets ?? [],
      is_dynamic: step.is_dynamic,
    }
  } else if (step.type === StepType.Text) {
    details = {
      startEditIndex: shiftColumnIndex,
    }
  } else if (step.type === StepType.Time) {
    details = {
      date: step.date,
    }
  } else if (step.type === StepType.Number || step.type === StepType.Photo) {
    details = {
      startEditIndex: shiftColumnIndex,
    }
  } else if (step.type === StepType.Boolean) {
    details = {
      means_of_measure: step.means_of_measure,
      is_not_applicable: step.is_not_applicable,
    }
  } else if (step.type === StepType.Checkbox) {
    details = {
      is_activated: true,
    }
  } else if (step.type === StepType.Calculator) {
    details = {
      calcul: step.calcul,
    }
  } else if (step.type === StepType.List) {
    const selectOptions = handleOptions(step.list_data.list_id, listOptions)
    details = {
      selectOptions,
      isMultiple: step.list_data.is_multiple,
      isCustomized: step.list_data.is_customized,
    }
  }
  details.startEditIndex = shiftColumnIndex
  details.initialShiftIndex = shiftColumnIndex
  details.samplingAreas = samplingAreas
  details.compute_sampling = computeSampling
  details.frequency = step.frequency
  details.minType = step.min_type
  details.maxType = step.max_type
  details.updatedShiftIndex =
    !isUndefinedOrNullOrEmpty(updatedShiftIndex) && updatedShiftIndex !== 0
      ? updatedShiftIndex
      : shiftColumnIndex
  return details
}

export const handleOptions = (listId: string, listOptions: any[]) => {
  const listValues = listOptions
    .find((p) => p.id === listId)
    ?.list_value.filter((e) => e.deleted_at === null)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return parseArrayObjectToKeyAndLabel(listValues, 'option_value', 'option_key')
}

export const getCellHeight = (value, fontSize = 7) => {
  return getElementHeight(value, PDF_CELL_WIDTH - 36, fontSize) + 35
}

export const replaceComputePlaceholders = (
  computeExpression: string,
  answer: JInputData,
  steps: JStep[],
  decimalDigits = 0,
) => {
  let resultString = computeExpression
  if (
    answer.context?.calculContext &&
    answer.context?.calculContext?.length > 0
  ) {
    answer.context?.calculContext.forEach((contextValue) => {
      // Replace the first occurrence of %d in each iteration
      if (resultString.includes('%d'))
        resultString = resultString.replace('%d', contextValue)
      else
        for (const stepId of steps.map((step) => step.id)) {
          if (resultString.includes(stepId)) {
            resultString = resultString.replace(
              stepId,
              contextValue ?? steps?.find((s) => s.id === stepId)?.name,
            )
            break
          }
        }
    })
  }
  const computationResult = decimalDigits
    ? Number.parseFloat(answer.value).toFixed(decimalDigits)
    : answer.value
  return `${resultString} = ${computationResult}`
}

export const computeRowHeight = ({
  tags,
  name,
  type,
  description,
  descriptionHeight,
  onExport,
  onPdf,
}: any) => {
  const width = onPdf
    ? EXPORT_NAME_COLUMN_WITH - 35
    : NORMAL_NAME_COLUMN_WITH - 35
  const elementHeight = onPdf
    ? getElementHeight(description, width)
    : descriptionHeight || getElementHeight(description, width)
  const titleHeight = getTitleHeight(name, width)
  const tagsHeight = getTagsHeight(tags, width)
  let height = elementHeight + titleHeight + tagsHeight + 45

  const minRowHeight = onExport ? (onPdf ? 100 : 150) : 120
  if (height < minRowHeight) height = minRowHeight

  if (type === StepType.Photo && height < 240) height = 240

  return height
}

export const getTagsHeight = (tags, width) => {
  const tagsElement = document.createElement('div')
  tagsElement.style.cssText = `display:flex; gap:8px; flex-wrap: wrap; padding-top:4px; padding-bottom:4px; top:-9999px; opacity:0; width:${width}px`
  for (const tag of tags) {
    const tagElement = document.createElement('p')
    tagElement.innerHTML = tag.value
    tagElement.style.cssText =
      'padding-left:8px;padding-right:8px;height:24px; padding-x: 4px; opacity:0; word-break: keep-all'
    tagsElement.appendChild(tagElement)
  }

  document.body.appendChild(tagsElement)
  // meassure it
  const height = tagsElement.clientHeight
  tagsElement.parentNode.removeChild(tagsElement)
  return height
}

export const getTitleHeight = (description, width) => {
  const titleElement = document.createElement('div')
  titleElement.innerHTML = description
  titleElement.style.cssText = `position:fixed; top:-9999px; opacity:0; width:${width}px; line-height: 1.5; font-weight: 700; word-break: keep-all`
  document.body.appendChild(titleElement)
  // meassure it
  const height = titleElement.clientHeight
  titleElement.parentNode.removeChild(titleElement)
  return height
}

export const getElementHeight = (description, width, fontSize: number) => {
  const descriptionElement = document.createElement('div')
  descriptionElement.style.cssText = `word-break: keep-all; white-space: normal; opacity:0; width:${width}px; text-align: justify; display: block;font-size:${fontSize}px`
  descriptionElement.innerHTML = description
  document.body.appendChild(descriptionElement)
  // meassure it
  const height = descriptionElement.clientHeight
  descriptionElement.parentNode.removeChild(descriptionElement)
  return height
}

export const computeTypeRepetitionInStep = (step: any): JStep => {
  step.typeRepetition = []
  if (!step.repetitions) return step
  for (const repetition of step.repetitions) {
    if (repetition.repetition_type === RepetitionType.sampling)
      step.typeRepetition.push(RepetitionType.sampling)
    if (repetition.repetition_type === RepetitionType.formula)
      step.typeRepetition.push(RepetitionType.formula)
    if (repetition.repetition_type === RepetitionType.frequency)
      step.typeRepetition.push(RepetitionType.frequency)
  }

  return step
}

export const setReportRepetition = (step: JStep): JStep => {
  step.repetitions = step.repetitions?.map((q) => {
    const proxy = documentSettingsStore()
      .filterDocumentSettings(DocumentSettingsType.repetition)
      ?.find((r) => r.id === q || r.id === q.id)
    return { ...proxy }
  })

  const repetitionStepValue = step.repetitions?.find(
    (repetition: any) => repetition.repetition_type === RepetitionType.sampling,
  )

  if (repetitionStepValue) {
    step.frequency = repetitionStepValue?.value
    step.sample = Number(repetitionStepValue?.sample)
  }

  const repetitionFormulas = step.repetitions?.filter(
    (repetition: any) => repetition.repetition_type === RepetitionType.formula,
  )

  if (repetitionFormulas) {
    const formulas = documentSettingsStore()
      .filterDocumentSettings(DocumentSettingsType.formula)
      .filter((e) => e.type === RepetitionType.formula)
    step.formulasData = [] as any
    repetitionFormulas.forEach((rep) => {
      const formula = formulas.find(
        (q) => q.id === rep.value || q.id === rep.id,
      )
      step.formulasData.push({
        type: formula.lookups_type?.length ? formula.lookups_type[0] : '',
        nature: rep.additional_filter?.nature || '',
        previewInputs: formula.preview_inputs || [],
      })
    })
  }

  step.typeRepetition = step.repetitions?.map((q) => q.repetition_type)
  return step
}

// renderer outside of the function so it's initialized once
const renderers = [
  { type: StepType.Checkbox, renderer: CellRendererCheckbox },
  { type: StepType.Boolean, renderer: CellRendererRadio },
  { type: StepType.List, renderer: CellRendererSelect },
  { type: StepType.Time, renderer: CellRendererTime },
  { type: StepType.Photo, renderer: CellRendererPhoto },
  { type: StepType.Calculator, renderer: CellRendererCalculator },
]

export const getCellRendererSelector = (params: any) => {
  return {
    component:
      renderers.find((e) => e.type === params.data.type)?.renderer ||
      CellRendererString,
  }
}

export const addDefaultHeader = (
  hasAttachements: boolean,
  answers?: any,
  onExport,
  reportId,
) => {
  // static columns
  const columnsDefinition = [] as any
  columnsDefinition.push({
    headerName: '',
    headerClass: 'header',
    headerComponent: CustomHeader,
    headerComponentParams: {
      static: true,
    },
    field: 'id',
    colId: 'id',
    index: 0,
    answers,
    flex: 1,
    pinned: 'left',
    width: 50,
    height: 25,
    cellRenderer: CellRendererID,
    suppressMovable: true,
    reportId,
  })

  columnsDefinition.push({
    headerName: i18n.global.t('document.name'),
    headerClass: 'header',
    headerComponent: CustomHeader,
    headerComponentParams: {
      static: true,
    },
    field: 'name',
    colId: 'name',
    index: hasAttachements ? 2 : 1,
    wrapText: true,
    flex: 1,
    pinned: 'left',
    width: onExport ? EXPORT_NAME_COLUMN_WITH : NORMAL_NAME_COLUMN_WITH,
    height: 50,
    cellStyle: {
      'border-right': '0 !important',
    },
    cellRenderer: CellRendererNameDescriptionDetail,
    suppressMovable: true,
  })

  return columnsDefinition
}

export const cellStyle = (pinned = false) => {
  const style = {
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    height: '100%',
    'white-space': 'normal',
    'line-height': '18px',
    overflow: 'visible',
  }

  if (pinned) {
    style['border-left'] = 'solid 1px'
    style['border-left-color'] = '#dde2eb'
  }

  return style
}

export const disableCellStyle = (pinned = false, isConditionnal = false) => {
  const style = {
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    height: '100%',
    'white-space': 'normal',
    'line-height': '18px',
    overflow: 'visible',
    'background-color': '#F4F4F4',
  }

  isConditionnal
    ? (style['background-color'] = '#DFE4EC')
    : (style['background-color'] = '#F4F4F4')

  if (pinned) {
    style['border-left'] = 'solid 1px'
    style['border-left-color'] = '#dde2eb'
  }

  return style
}

export const targetCellStyle = (pinned = false) => {
  const style = {
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    height: '100%',
    'white-space': 'normal',
    'line-height': '18px',
    overflow: 'visible',
    'background-color': '#E8EAFD',
  }
  if (pinned) {
    style['border-left'] = 'solid 1px'
    style['border-left-color'] = '#dde2eb'
  }

  return style
}

const chooseRepetition = (repetition: any): any => {
  const repetitionTypes = [
    RepetitionType.sampling,
    RepetitionType.formula,
    RepetitionType.frequency,
  ]

  for (const repetitionType of repetitionTypes) {
    if (repetition.includes(repetitionType)) return repetitionType
  }

  return repetition[0]
}

export const computeSamplingByType = (
  step: any,
  startingColumnIndex: number,
  endColumnIndex: number,
) => {
  const { frequency, sample, typeRepetition } = step

  const repetition = chooseRepetition(typeRepetition)
  let finalSampling = [] as any
  let finalAqlTags = {} as any

  switch (repetition) {
    case RepetitionType.formula:
      finalSampling = step.sampling
      finalAqlTags = step.aql_tags

      break
    case RepetitionType.sampling:
      finalSampling = computeSampling(
        frequency,
        sample,
        startingColumnIndex,
        endColumnIndex,
      )
      break
    case RepetitionType.frequency:
      finalSampling = computeFrequency(
        step.repetitions[0].value,
        startingColumnIndex,
        endColumnIndex,
      )
      break
    default:
      finalSampling = computeNoRepetition(endColumnIndex)
      break
  }
  return { sampling: finalSampling, aqlTags: finalAqlTags }
}

export const computeFrequency = (
  listOfFrequencies: string[],
  startIndex: number,
  endIndex: number,
) => {
  const samplingList: boolean[] = []
  const grid = gridStore().grid
  if (!grid) return []
  const frequency = grid?.document?.frequency

  let date = getReportStartingTime(frequency, grid?.report)

  for (let i = startIndex; i < endIndex; i++) {
    const datesInString = getTimeFrequencyDate(date)
    samplingList.push(listOfFrequencies.includes(datesInString))
    date = nextDate(date, frequency)
  }

  return samplingList
}

export const computeFormulaSampling = async (
  formulasData: { type: string; nature: string; step_id: string }[],
  endColumnIndex: number,
  contexts: ContextScopeViewModel[],
) => {
  if (!formulasData.length) return

  // Collect all formula types and natures
  const formulaRequests = formulasData.map((data, index) => ({
    formula_type: data.type,
    nature: data.nature,
    context: contexts[index],
    step_id: data.step_id,
  }))

  // Make a single backend call with all formulas and contexts
  const fetchedData = await api.getFormulasData({
    formulas: formulaRequests,
    production_order_id: contexts[0].tags.lotName,
    grid_size: contexts[0].tags.gridSize,
  })

  const result = {}

  // Process each step
  for (const [stepId, stepData] of Object.entries(fetchedData)) {
    const stepSampling = []
    let stepAqlTags = null

    // Process formulas within step
    for (const data of stepData) {
      if (!data) continue

      const resultArray = new Array(Number(endColumnIndex)).fill(false)
      data.control_points?.forEach((point) => {
        resultArray[point - 1] = true
      })
      stepSampling.push(resultArray)

      if (data.y && Object.keys(data?.y)?.length) {
        stepAqlTags = data.y
      }
    }

    // Normalize and merge sampling for this step
    const maxSize = Math.max(...stepSampling.map((arr) => arr.length))
    const normalizedSampling = stepSampling.map((arr) => {
      if (arr.length < maxSize) {
        return [...arr, ...Array(maxSize - arr.length).fill(false)]
      }
      return arr
    })

    result[stepId] = {
      sampling: mergeSampling(normalizedSampling),
      aqlTags: stepAqlTags,
    }
  }
  return result
}

const mergeSampling = (sampling) => {
  return _.reduce(sampling, (mergedSampling, sampling) => {
    return _.map(mergedSampling, (element, index) => {
      return element || sampling[index]
    })
  })
}

export const computeSampling = (
  frequency: number,
  sample: number,
  startingColumnIndex: number,
  endColumnIndex: number,
): any[] => {
  const samplingResult: boolean[] = []
  if (sample === undefined && frequency === undefined) {
    sample = 1
    frequency = 1000
  }

  for (
    let currentCol = startingColumnIndex;
    currentCol < endColumnIndex;
    currentCol++
  ) {
    const rest = currentCol % frequency

    const isActivated = rest >= 0 && rest <= Number(sample) - 1
    samplingResult.push(isActivated)
  }
  return samplingResult
}

export const computeGlobalsampling = (allStepsSamplingAreas: any[]) => {
  const mergedSampling = []
  for (let i = 0; i < allStepsSamplingAreas.flat()?.[0]?.length; i++) {
    const columns = allStepsSamplingAreas.flat()?.map((t) => t && t[i])
    mergedSampling.push(columns?.some((x) => x === true))
  }
  return mergedSampling
}

export const computeNoRepetition = (endColumnIndex: number): any[] => {
  const samplingAreas = Array(Number(endColumnIndex))

  samplingAreas.fill(false, 0, endColumnIndex)
  samplingAreas[0] = true

  return samplingAreas
}

export const getHeaderComponentParams = (
  steps: any[],
  inputData: JInputData[],
  colIndex: number,
  previousLastColumnIndex: number,
  currentLastColumnIndex: number,
  gridHeader: string,
  docTrigger: string,
  maxIndexUsed: number,
  disabled = false,
  alerts: AlertViewModel[] = [],
  role = RoleGrid.editor,
  frequencyTime: string = '',
  isHistory = false,
) => {
  const currentColumnState = getColumnState(
    steps,
    inputData,
    colIndex,
    currentLastColumnIndex === colIndex,
    maxIndexUsed,
    disabled,
    role,
    isHistory,
  )
  const currentColAlerts = alerts.filter((documentAlert) =>
    documentAlert.erroredSteps.some(
      (erroredStep) => erroredStep.answer.colId === colIndex,
    ),
  )

  return {
    name: getColumnId(colIndex, gridHeader),
    firstUpdateOnColumn: getLastActionUpdateOnColumn(
      inputData,
      colIndex,
      'asc',
    ),
    lastUpdateOnColumn: getLastActionUpdateOnColumn(inputData, colIndex),
    columnState: currentColumnState,
    docTrigger,
    lastColumnIndex: previousLastColumnIndex,
    colIndex,
    alerts: currentColAlerts,
    alert: currentColAlerts[0],
    frequencyTime,
  }
}

export const getColumnId = (index: number, gridHeader: string) => {
  const newGridHeader = Array.isArray(gridHeader)
    ? gridHeader[index % gridHeader.length]
    : gridHeader
  const colId = Array.isArray(gridHeader)
    ? Math.floor(index / gridHeader.length) + 1
    : index + 1

  return `${newGridHeader || t('report.control')} ${colId}`
}

export const getLastActionUpdateOnColumn = (
  inputData: JInputData[],
  colIndex: number,
  order: 'asc' | 'desc' = 'desc',
) => {
  const colData = inputData
    ?.filter((data) => data.col_id === colIndex)
    .map((data) => {
      return {
        ...data,
        update_date: new Date(data.update_date),
      }
    })
  const recentAnswers = _.orderBy(colData, 'update_date', order)
  return { ...recentAnswers[0] }
}

export const getLastActionUpdateOnGrid = (steps: any[]) => {
  const stepAnswers = steps
    .map((step: JStep) => {
      return step.answers.sort((a, b) => b.update_date - a.update_date)[0]
    })
    .filter(Boolean)

  const recentAnswers = stepAnswers.sort(
    (a, b) => b.update_date - a.update_date,
  )
  return { ...recentAnswers[0] }
}

export const isEditable = (
  params: any,
  columnIndexOffShift: number,
  userId: string,
  allInputData: any[],
  currentDocument: any,
  role: string,
  currentReport: any,
  disableCustomComponent: boolean,
  hasNoReportPermission: boolean,
  history = false,
) => {
  if (history) return false
  let editable = true
  const invalidType =
    ![StepType.Measure, StepType.Text, StepType.Number].includes(
      params.data.type,
    ) && disableCustomComponent
  const step = currentReport?.steps[params?.node?.rowIndex] as JStep
  const colIndex = params.colDef.index - params.data?.shiftIndex

  if (step?.disabled) return false

  if (
    checkIsCellDisabled(
      step?.col_ids_to_disable,
      colIndex,
      params?.data?.parentFrequency,
      step?.disabled,
    )
  )
    return false

  if (role === RoleGrid.preview) return !invalidType

  const lastCellAnswer = allInputData?.find(
    (e) =>
      e.row_id === params?.node?.rowIndex && e.col_id === columnIndexOffShift,
  )
  const isMyCell = !lastCellAnswer || lastCellAnswer?.updated_by === userId
  const amountOfHoursBeforeDisable = siteStore().site.flags
    .mustJustifyEveryCellEdit
    ? 0
    : 10
  const isExpired =
    lastCellAnswer &&
    addHours(
      new Date(lastCellAnswer?.update_date),
      amountOfHoursBeforeDisable,
    ) < new Date()

  const isHistory = params?.data?.isHistory
  if (
    !isMyCell ||
    isExpired ||
    hasNoReportPermission ||
    isHistory ||
    invalidType ||
    currentReport.status === ReportStatus.finished
  )
    editable = false

  return editable
}

export const getClosestElementLessThanIndex = (array, index) => {
  const closestElements =
    _.filter(array, (element) => element <= index) || array[0]
  return _.max(closestElements)
}

export const getCellStyle = (
  params: any,
  currentReport: JReport,
  isHistory: boolean,
  columnIndexOffShift: number,
  highlightedCols: number[],
  pinnedColumnIndex = null as number | null,
  onPdf: boolean = false,
) => {
  const isDynamiqueCol =
    highlightedCols?.find((e) => e === columnIndexOffShift) !== undefined
  let style: any
  let editable = true
  const colIndex = params.colDef.realIndex
  const step = currentReport?.steps[params?.data?.index] as JStep
  editable = params?.colDef?.editable(params, false)
  let isPinned = false

  if (pinnedColumnIndex) isPinned = params.colDef?.index === pinnedColumnIndex
  if (
    checkIsCellDisabled(
      step?.col_ids_to_disable,
      colIndex,
      params?.data?.parentFrequency,
      step?.disabled,
    )
  )
    style = disableCellStyle(isPinned, true)
  else if (isHistory)
    style = isDynamiqueCol ? targetCellStyle(isPinned) : cellStyle(isPinned)
  else if (isDynamiqueCol) style = targetCellStyle(isPinned)
  else
    style = editable || onPdf ? cellStyle(isPinned) : disableCellStyle(isPinned)

  return style
}

export const getCellEditorSelector = (params: any, isHistory: boolean) => {
  if (isHistory) return

  if ([StepType.Measure, StepType.Number].includes(params.data.type)) {
    return { component: NumericCellEditor }
  } else if (params.data.type === StepType.Text) {
    return {
      component: TextAreaCellEditor,
    }
  }
}

export const refreshSampling = (
  latestSamplingArea: any,
  frequency: number,
  sample: number,
  startingColumnIndex: number,
  endingColIndex: number,
  shiftIndex: number,
  gridSize = 0,
) => {
  if (latestSamplingArea) {
    if (!frequency && !sample) {
      latestSamplingArea[startingColumnIndex - shiftIndex] = true
      return latestSamplingArea
    }

    const newSamplingArea = computeSampling(
      frequency,
      sample,
      0,
      endingColIndex - startingColumnIndex,
    )
    const clonedSampling = _.cloneDeep(latestSamplingArea)

    const mergedSampling = _.take(
      clonedSampling,
      startingColumnIndex - shiftIndex,
    )
    newSamplingArea.forEach(
      (p) => mergedSampling.length < gridSize && mergedSampling.push(p),
    )
    return mergedSampling
  }
}

export const addSampling = (
  latestSamplingArea: any,
  frequency: number,
  sample: number,
  startingColumnIndex: number,
  endingColIndex: number,
  shiftColumnIndex: number,
) => {
  if (latestSamplingArea) {
    const newSamplingArea = computeSampling(
      frequency,
      sample,
      startingColumnIndex - shiftColumnIndex,
      endingColIndex - shiftColumnIndex,
    )
    const mergedSampling = _.cloneDeep(latestSamplingArea)

    newSamplingArea.forEach((p) => mergedSampling.push(p))
    return mergedSampling
  }
}

export const setNotificationTime = (
  documentFrequency: Frequency,
  colIndex: number,
  report: JReport,
) => {
  const startingTime = getReportStartingTime(documentFrequency, report)

  return addFrequencyTime(
    startingTime,
    documentFrequency.type,
    documentFrequency.every * colIndex,
  ).getTime()
}

export const triggerNotification = async ({
  document,
  report,
  reportPath,
  nbColumns,
}) => {
  if (!document.frequency.enabled || !document.frequency.enableNotification)
    return

  await NotificationController.createNotification({
    id: '',
    path: reportPath,
    type: NotificationType.REMINDER,
    assigneeId: usersStore().user.id || '',
    assigneeIds: [],
    cells: Array.from(Array(+nbColumns).keys()).map((index) => {
      return {
        date: setNotificationTime(document.frequency, index, report),
        cellInfo: {
          col: index,
          status: NotificationState.NEW,
        },
      }
    }),
    reportInfo: {
      id: report.id,
      name: document.name,
    },
    status: {
      mode: NotificationMode.IN_APP,
      state: NotificationState.NEW,
    },
  })
}

export const hideRows = (
  steps: JStep[],
  currentValueBranchings: StepBranching[],
  previousValueBranchings: StepBranching[],
  rangeToUpdate,
  report_id: null,
) => {
  const currentValueStepIds = currentValueBranchings.flatMap(
    (b) => b.action_details?.steps_to_display,
  )
  const previousValueStepIds = previousValueBranchings.flatMap(
    (b) => b.action_details?.steps_to_display,
  )

  const stepIdsIndexToRemove = _.difference(
    previousValueStepIds,
    currentValueStepIds,
  )
  const stepsIndexToRemove = steps.filter((step) =>
    stepIdsIndexToRemove.includes(step.id),
  )
  let gridSize: any

  gridSize = isUndefinedOrNullOrEmpty(report_id)
    ? gridStore().grid?.reportGridSize()
    : gridStore(String(report_id)).grid?.reportGridSize()

  if (gridSize) gridSize = Number(gridSize)

  stepsIndexToRemove.forEach((step) => {
    step.col_ids_to_disable = step.col_ids_to_disable?.length
      ? _.uniq([...step.col_ids_to_disable, ...rangeToUpdate])
      : rangeToUpdate

    if (step.col_ids_to_disable?.length === gridSize && !step.answers?.length) {
      step.hidden = true
      step.disabled = true
    } else {
      step.hidden = false
      step.disabled = false
    }
  })
}

export const disableRows = (
  stepsToHide: JStep[],
  stepIdsToHide: string[],
  answeredStepIds: any[],
) => {
  stepsToHide.reduce((acc, e) => {
    if (e.answers.length) {
      _.pull(stepIdsToHide, e.id) // no need to hide this step anymore as we are going to disable it
      if (!answeredStepIds.includes(e.id)) {
        acc.push(e.id) // step to be greyed
        e.disabled = true
        e.hidden = false
      }
    }
    return acc
  }, [] as any)
}

export const displayRows = (
  steps: JStep[],
  branchings: StepBranching[],
  colId: number,
  currentValueBranchings: StepBranching[],
  rangeToUpdate: any[],
) => {
  const stepsToDisplaySet = new Set(
    branchings.flatMap((b) => b.action_details?.steps_to_display),
  )
  const currentValueStepIds = currentValueBranchings.flatMap(
    (b) => b.action_details?.steps_to_display,
  )

  steps.forEach((step) => {
    if (stepsToDisplaySet.has(step.id)) {
      if (
        currentValueBranchings.length &&
        currentValueStepIds.includes(step.id)
      )
        step.col_ids_to_disable = step.col_ids_to_disable?.filter(
          (colId) => !rangeToUpdate.includes(colId),
        )

      step.hidden = false
      step.disabled = false
    }
  })
}

export const handleBranchingForNewColumn = (
  steps,
  newColId,
  report_id = null,
) => {
  for (let rowIndex = 0; rowIndex < steps.length; rowIndex++) {
    const step = steps[rowIndex]

    if (step.branching?.length > 0) {
      const lastTrueSamplingArea = _.lastIndexOf(
        step.last_sampling_areas.filter((value, index) => index <= newColId),
        true,
      )
      const answersOfLastSamplingArea = step.answers.filter(
        (answer) => answer.col_id === lastTrueSamplingArea,
      )
      const answerOfLastSamplingArea = _.orderBy(
        answersOfLastSamplingArea,
        'update_date',
        'desc',
      )?.[0]?.value

      handleConditionalSteps(
        step,
        newColId,
        steps,
        rowIndex,
        answerOfLastSamplingArea,
        true,
        report_id,
      )
    }
  }
}

export const handleConditionalSteps = (
  currentStep: any,
  colId: number,
  steps: JStep[],
  rowIndex: number,
  value: string,
  newColumn = false,
  report_id?: null,
  doubleCheckData?: JInputData,
  allInputdata?: JInputData[],
  fromDoubleCheck = false,
) => {
  let values = _.uniqBy(
    steps[rowIndex]?.answers,
    (obj) => `${obj.col_id}-${obj.row_id}`,
  )

  values = values.flatMap((e) => e.value)
  // find every branching matching the values we have in the step
  const branchings =
    currentStep?.branching?.filter((e) =>
      e.trigger.conditions.some((condition) =>
        values.some((v) =>
          evaluateCondition(
            condition.operator,
            condition.value,
            v,
            currentStep,
            colId,
            doubleCheckData,
          ),
        ),
      ),
    ) || []

  const currentValueBranchings =
    currentStep?.branching?.filter((e) =>
      e.trigger.conditions.some((condition) =>
        evaluateCondition(
          condition.operator,
          condition.value,
          value,
          currentStep,
          colId,
          doubleCheckData,
        ),
      ),
    ) || []

  const previousCellValues =
    allInputdata?.filter((e) => e.col_id === colId && e.row_id === rowIndex) ||
    []

  const previousCellValue =
    previousCellValues?.length > 1 ? previousCellValues[1].value : []
  let currentCellInput = new JInputData({})

  if (fromDoubleCheck) {
    currentCellInput = _.cloneDeep(previousCellValues[0])
    // as we have no history for the double chek result for now, the previous value is just the opposite of the current value as it is a boolean
    currentCellInput.double_check_result = _.cloneDeep(
      !currentCellInput?.double_check_result,
    )
  }

  const previousCellValueBranching =
    currentStep?.branching?.filter((e) =>
      e.trigger.conditions.some((condition) =>
        evaluateCondition(
          condition.operator,
          condition.value,
          fromDoubleCheck ? currentCellInput?.value : previousCellValue,
          currentStep,
          colId,
          fromDoubleCheck ? currentCellInput : previousCellValues[1],
        ),
      ),
    ) || []

  const futureSamplingAreaStartingFromColId =
    currentStep.last_sampling_areas.filter((value, index) => index > colId)
  let nextBranchingStepFillableColumnId =
    futureSamplingAreaStartingFromColId.indexOf(true)
  nextBranchingStepFillableColumnId =
    nextBranchingStepFillableColumnId > -1
      ? nextBranchingStepFillableColumnId
      : futureSamplingAreaStartingFromColId.length
  const rangeToUpdate = _.range(
    colId,
    nextBranchingStepFillableColumnId
      ? colId + nextBranchingStepFillableColumnId + 1
      : colId + 1,
    1,
  )

  if (newColumn) {
    let cellNotValidBranching
    cellNotValidBranching =
      currentStep?.branching?.filter((e) =>
        e.trigger.conditions.some(
          (condition) =>
            !evaluateCondition(
              condition.operator,
              condition.value,
              value,
              currentStep,
              colId,
            ),
        ),
      ) || []

    hideRows(
      steps,
      currentValueBranchings,
      cellNotValidBranching,
      rangeToUpdate,
      report_id,
    )
  }
  if (
    StepTypesWithBranching.includes(currentStep.type) &&
    currentStep.branching?.length
  ) {
    displayRows(steps, branchings, colId, currentValueBranchings, rangeToUpdate)
    hideRows(
      steps,
      currentValueBranchings,
      previousCellValueBranching,
      rangeToUpdate,
      report_id,
    )
  }
}

export const evaluateCondition = (
  operator: string,
  conditionValue: string,
  value: string | string[],
  step: any,
  colId: number | undefined,
  doubleCheckData?: JInputData,
) => {
  switch (step.type) {
    case StepType.List:
      return evaluateBranchingConditionForListStep(
        operator,
        conditionValue,
        value,
      )
    case StepType.Boolean:
      return evaluateBranchingConditionForBooleanStep(
        operator,
        conditionValue,
        value,
        step,
        colId,
        doubleCheckData,
      )
    case StepType.Measure:
    case StepType.Calculator:
      return evaluateBranchingConditionForMeasureStep(
        operator,
        conditionValue,
        value?.result ?? value,
        step,
        colId,
        doubleCheckData,
      )
    // add other cases here as needed
    default:
      throw new Error(`Unsupported step type: ${step}`)
  }
}

const evaluateBranchingConditionForListStep = (
  operator: string,
  conditionValue: string,
  value: string | string[],
) => {
  switch (operator) {
    case '===':
      return Array.isArray(value)
        ? value.includes(conditionValue)
        : conditionValue === value
    case '>':
      return Array.isArray(value)
        ? value.some((v) => v > conditionValue)
        : conditionValue > value
    case '<':
      return Array.isArray(value)
        ? value.some((v) => v < conditionValue)
        : conditionValue < value
    // add other cases here as needed
    default:
      throw new Error(`Unsupported operator: ${operator}`)
  }
}

const evaluateBranchingConditionForBooleanStep = (
  operator: string,
  conditionValue: string,
  value: string | string[],
  step: any,
  colId: number | undefined,
  doubleCheckData?: JInputData,
) => {
  const isStepValid = validateStepKo(value, step, colId, false, doubleCheckData)

  if (value === undefined || value === null) return false

  if (operator === '===')
    return (
      (conditionValue === SpecialStepBranchingValues.Valid && !isStepValid) ||
      (conditionValue === SpecialStepBranchingValues.NonValid && isStepValid)
    )
  return false
}

const evaluateBranchingConditionForMeasureStep = (
  operator: string,
  conditionValue: string,
  value: string | string[],
  step: any,
  colId: number | undefined,
  doubleCheckData?: JInputData,
) => {
  if (value === undefined) return false
  const tolerance =
    settingsStore().filterSettings(SettingsType.tolerance)[0]?.value || 0
  const isStepUnvalid = validateStepKo(
    value,
    step,
    colId,
    false,
    doubleCheckData,
  )
  const isStepInUncertaintyZone = validateStepBorderLine(
    value,
    step,
    tolerance,
    colId,
  )
  if (operator === '===') {
    return (
      (conditionValue === SpecialStepBranchingValues.Valid &&
        !isStepUnvalid &&
        !isStepInUncertaintyZone) ||
      (conditionValue === SpecialStepBranchingValues.NonValid &&
        isStepUnvalid &&
        !isStepInUncertaintyZone) ||
      (conditionValue === SpecialStepBranchingValues.Uncertain &&
        isStepInUncertaintyZone)
    )
  }
  return false
}
