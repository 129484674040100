import localforage from 'localforage'
import _ from 'lodash'
import { CACHED_OBJECTS } from '~/config/local-forage'
import bcrypt from 'bcryptjs'
export const redirectToPage = async (user) => {
  if (user.is_reset_password === false) return '/auth/force-reset-pass'

  let targetedPage: any = null
  const latestVisitedPageUrl = await localforage.getItem(
    `${CACHED_OBJECTS.LATEST_VISITED_URL}_${user.id}`,
  )

  const switchUserRedirectionPath = localStorage.getItem(
    'switch_user_redirection_path',
  )
  if (!_.isEmpty(switchUserRedirectionPath)) {
    await localStorage.removeItem('switch_user_redirection_path')
    targetedPage = switchUserRedirectionPath
  }

  if (!_.isEmpty(latestVisitedPageUrl)) {
    await localforage.removeItem(
      `${CACHED_OBJECTS.LATEST_VISITED_URL}_${user.id}`,
    )
    targetedPage = latestVisitedPageUrl
  }

  return targetedPage
}
export const verifyIfUserHaveAccessRightToPath = (user, route) => {
  const requiredAccessRights: string[] = route.meta.requiredAccessRights

  if (requiredAccessRights?.length) {
    if (
      requiredAccessRights.every(
        (requiredAccessRight) =>
          !user?.permissions?.[`${requiredAccessRight}s`]?.length,
      )
    )
      return false
  }

  return true
}

export const checkIfPasswordIsUsedBefore = async (
  password: string,
  passwordHashes: string[],
  numberOfOldPasswords = 4,
) => {
  if (!passwordHashes?.length) {
    return false
  }
  const recentHashes = passwordHashes.slice(-1 * numberOfOldPasswords)

  for (const hash of recentHashes) {
    const match = await bcrypt.compare(password, hash)
    if (match) {
      return true
    }
  }
}

export const initLoginConnectionSocket = (
  onMessage: any,
): WebSocket | undefined => {
  const ws = new WebSocket('ws://127.0.0.1:8484')
  console.log('listening...')

  ws.onmessage = (e) => {
    onMessage(e.data)
  }

  ws.onclose = (e) => {
    if (e.code !== 3002 && window.location.pathname.includes('auth/login')) {
      setTimeout(() => {
        initLoginConnectionSocket(onMessage)
      }, 3000)
    }
  }

  ws.onerror = (err) => {
    console.log('Error while listening')
    ws.close()
  }
  return ws
}
