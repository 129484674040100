<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import localforage from 'localforage'
import { storeToRefs } from 'pinia'
import { addMinutes, minutesToMilliseconds } from 'date-fns'
import ViewsController from './controllers/views'
import type { JSite } from './models/sites'
import { retryUpdateInputData } from './controllers/reports/reportsController'
import { Icons } from './models/Icon'
import { getUserLanguage } from './helpers/locales'
import { CACHED_OBJECTS } from '~/config/local-forage'
import { VERSION } from '~/config/version'
import { loadUser, logout } from '~/controllers/authentication'
import { usersStore } from '~/store/users'
import { siteStore } from '~/store/site'
import { hotReload, versionToNumber } from '~/helpers/UtilsHelper'
import { apiStore } from '~/store/api'
import loggerHelper from '~/helpers/LoggerHelper'
import startup from '~/helpers/Startup'
import { gS } from '~/store/global'
import apiHelper from '~/helpers/ApiHelper'
import { fb_auth } from '~/services/firebase'
import { OfflineHandler } from '~/services/offline'
import DBHelper from '~/helpers/dbHelper'
import SimulatorButton from './components/Mes/Simulator/SimulatorButton.vue'
import Intercom, { boot } from '@intercom/messenger-js-sdk'
import { shutdown } from '@intercom/messenger-js-sdk'
import { startReadingFromSerialPort } from '~/modules/SerialPortReader'

const dbHelper = new DBHelper()
const route = useRoute()
const router = useRouter()
const api = apiStore().getApiClient
const { locale } = useI18n()
const user = computed(() => usersStore().user)

useHead({
  title: `Juno - ${VERSION}`,
  meta: [{ name: 'description', content: `Juno web app ${VERSION}` }],
})

const appData: any = reactive({
  loading: false,
  offline: false,
  showPopUp: false,
})

const state = reactive({
  maxActiveTime: 480 as number,
  currentSite: {} as JSite,
  // In order to use deconnect function only one time
  userIsDeconnected: 0,
})

// offline init
const { isOnline } = storeToRefs(gS())

watch(isOnline, (value) => {
  if (value) OfflineHandler.syncPostData()
})

OfflineHandler.syncPostData()

apiHelper.startPingingJunoServer()
apiHelper.retryFailedPost()
// end offline init

const checkVersion = async () => {
  const status = await api.getAppStatus()
  const frontVersion = versionToNumber(VERSION)
  const serverVersion = versionToNumber(status?.version)
  // const currentTimestamp = addMinutes(new Date(), 4)
  //  && (status.releaseTimeStamp && currentTimestamp.getTime() > status.RELEASE_TIMESTAMP)
  if (frontVersion < serverVersion) {
    if (!status.forceReload) appData.showPopUp = true
    else await hotReload()
  }
}

const replayData = async () => {
  if (fb_auth.currentUser) await retryUpdateInputData()
}

const channel = new BroadcastChannel('logoutChannel')

const logNetworkInfo = async () => {
  if (import.meta.env.DEV) return

  const connection: any = navigator?.connection

  const occurence_date = new Date()
  const userData = usersStore().user
  const logData: any = {
    type: connection?.effectiveType,
    downlinkype: `${connection?.downlink} Mb/s`,
    rtt: `${connection?.rtt} ms`,
    client_id: userData?.client_id ? userData?.client_id : 'anonymous',
    client_name: userData?.client_name ? userData?.client_name : 'anonymous',
    site_name: userData?.site_name ? userData?.site_name : 'anonymous',
    uid: userData?.id ? userData?.id : 'anonymous',
    first_name: userData?.first_name ? userData?.first_name : 'anonymous',
    last_name: userData?.last_name ? userData?.last_name : 'anonymous',
    online: navigator.onLine,
    timestamp: occurence_date.getTime(),
    date: occurence_date,
    sync_status: 'todo',
    version: VERSION,
  }
  const logs: any = await localforage.getItem('connection-logs')
  const logsDataList = logs?.data ? logs?.data : []
  logsDataList.push(logData)
  if (navigator.onLine === true) {
    if (logsDataList?.length) {
      for (const f of logsDataList) {
        if (f.sync_status === 'todo') {
          f.sync_status = 'synched'
          await dbHelper.addDataToCollection('connection_logs', f)
        }
      }
    }
    appData.offline = false
  } else {
    appData.offline = true
  }
  localforage.setItem('connection-logs', { data: logsDataList })
}

const init = async () => {
  loggerHelper.logEvent('INIT_APP')
  // eslint-disable-next-line no-console

  if ('connection' in navigator)
    navigator.connection.addEventListener('change', logNetworkInfo)

  if (typeof window !== 'undefined') {
    checkVersion()
    appData.interval_ctx = setInterval(checkVersion, 1000 * 60 * 10) // ask for new version every 10 min
    appData.interval_replay = setInterval(replayData, 1000 * 60 * 4) // ask for data to replay every 5 min
  }

  appData.loading = true
  await loadUser()
  const userStore = usersStore().user
  if (
    userStore?.is_reset_password === false &&
    userStore?.is_reset_password !== null
  )
    router.push('/auth/force-reset-pass')

  if (userStore?.password_expired_at && !userStore?.sso_only)
    router.push('/auth/reset-expired-password')

  if (userStore) {
    await localforage.setItem('currentUser', JSON.stringify(userStore))
    localStorage.setItem('currentUser', JSON.stringify(userStore))
  }

  if (userStore?.role_ids?.length) {
    await startup.bootstrap()

    state.currentSite = siteStore().site

    if (state.currentSite?.flags?.idle_delay)
      state.maxActiveTime = state.currentSite.flags.idle_delay as number
    ViewsController.setupView()
  } else if (!userStore && !userStore?.role_ids?.length)
    router.push('/forbidden')

  appData.loading = false
}

let timeOut: ReturnType<typeof setTimeout>

const userActivityHandler = () => {
  timeOut = setTimeout(async () => {
    state.userIsDeconnected++
    if (state.userIsDeconnected === 1) {
      const currentUrl = route.fullPath
      const userId = usersStore().user.id
      localforage.setItem(
        `${CACHED_OBJECTS.LATEST_VISITED_URL}_${userId}`,
        currentUrl,
      )
      localforage.setItem(CACHED_OBJECTS.DISCONNECTED_NON_ACTIVITY, 'true')
      await logout()
    }
  }, minutesToMilliseconds(state.maxActiveTime))
}

const globalListener = async () => {
  state.maxActiveTime = (siteStore().site.flags?.idle_delay as number) ?? 480
  // if user is logged in
  if (usersStore().user.id?.length) {
    const lastActivity = (await localforage.getItem(
      CACHED_OBJECTS.LAST_ACTIVITY_TIME,
    )) as Date
    gS().setLastActivity(lastActivity)

    if (user.value?.is_idle_delay_enabled !== false) {
      if (
        lastActivity &&
        new Date() > addMinutes(lastActivity, state.maxActiveTime)
      ) {
        const currentUrl = route.fullPath
        const userId = usersStore().user.id
        localforage.setItem(
          `${CACHED_OBJECTS.LATEST_VISITED_URL}_${userId}`,
          currentUrl,
        )
        localforage.setItem(CACHED_OBJECTS.DISCONNECTED_NON_ACTIVITY, 'true')
        clearTimeout(timeOut)
        await logout()
        router.push('/auth/login')
        return
      }
      userActivityHandler()
    }
    localforage.setItem(CACHED_OBJECTS.LAST_ACTIVITY_TIME, new Date())
    clearTimeout(timeOut)
    state.userIsDeconnected = 0
  }
}

init()

watch([user, navigator.language], async () => {
  locale.value = await getUserLanguage(user.value)
})

window.addEventListener('storage', (event) => {
  if (event.storageArea != localStorage) return
  if (event.key === 'refresh_key') {
    location.reload()
  }
})

Intercom({
  app_id: import.meta.env.VITE_INTERCOM_APP_ID,
  user_id: user.value.id,
  name: user.value.full_name,
  email: user.value.email,
  created_at: user.value.create_date,
})

watch(
  () => siteStore().site,
  () => {
    if (siteStore().site?.flags?.show_intercom) {
      boot({
        app_id: import.meta.env.VITE_INTERCOM_APP_ID,
        user_id: user.value.id,
        name: user.value.full_name,
        email: user.value.email,
        created_at: user.value.create_date,
      })
    } else {
      shutdown()
    }
  },
)

// start reading
startReadingFromSerialPort()
</script>

<template>
  <div
    v-if="!isOnline"
    class="bg-[#FEE4E3] p-4 text-center"
    role="alert"
  >
    <div class="font-base inline-flex items-center space-x-2">
      <component
        :is="Icons.CLOUD_OFFLINE"
        class="text-red-600"
      />
      <div>{{ $t('global.you_are_offline') }}</div>
    </div>
  </div>

  <VersionPopUp v-model="appData.showPopUp" />

  <SimulatorButton />

  <router-view
    v-if="!appData.loading"
    @click="globalListener"
    @input="globalListener"
  />
  <div
    v-if="appData.loading"
    class="flex h-screen items-center justify-center"
  >
    <div
      style="border-top-color: transparent"
      class="h-12 w-12 animate-spin justify-center rounded-full border-4 border-solid border-blue-400"
    />
  </div>
</template>

<style>
.readOnlyMode {
  pointer-events: none;
}

.read-only {
  pointer-events: none !important;
}
</style>
