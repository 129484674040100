<script setup lang="ts">
import { AvatarSize } from '~/models/Style'
import type { JUser } from '~/models/users/JUser'
import { usersStore } from '~/store/users'
import { tCore } from '~/services/trpc'

const props = defineProps<{
  userId?: string
  size?: AvatarSize
  editable?: boolean
  fullname?: boolean
  selfUpdate?: boolean
  multiple?: boolean
  textBold?: boolean
  textPrimary?: boolean
  textSecondary?: boolean
  forceReload?: boolean
  recordDate?: Date
}>()

const state = reactive({
  user: {} as JUser,
})

const userInitials = computed(() => {
  const first_name = state.user?.first_name ? state.user?.first_name[0] : ''
  const last_name = state.user?.last_name ? state.user?.last_name[0] : ''
  const initials = `${first_name}${last_name}`.toUpperCase()

  return state.user?.trigramme || initials
})

watch(
  () => props.forceReload,
  () => {
    init()
  },
)

watch(
  () => props.userId,
  () => {
    init()
  },
)

const init = async () => {
  const user = props.selfUpdate
    ? (usersStore().user as JUser)
    : (usersStore().findUser(props.userId!) as JUser)

  if (props.recordDate && user) {
    const userHistory =
      await tCore.usersHistory.fetchUsersHistoryByUserIdAndDate.query({
        userId: user.id,
        date: props.recordDate,
      })
    if (userHistory) {
      state.user = userHistory.data
      return
    }
  }
  state.user = user
}

init()
</script>

<template>
  <div
    class="inline-flex items-center"
    :class="{ 'space-x-2': fullname }"
  >
    <div
      class="flex items-center justify-center rounded-full"
      :class="[
        {
          'h-[120px] w-[120px] text-6xl':
            size === AvatarSize.LARGE && !state.user?.trigramme,
          'h-[120px] w-[120px] text-5xl':
            size === AvatarSize.LARGE && state.user?.trigramme,
          'pb-2': !state.user?.picture_url && size === AvatarSize.LARGE,
          'h-8 w-8': size === AvatarSize.MEDIUM,
          'h-8 w-8 text-xxs':
            size === AvatarSize.MEDIUM && state.user?.trigramme,
          'h-5 w-5 text-xxs': size === AvatarSize.SMALL,
          'h-6 w-6 text-xs': !size && !state.user?.trigramme,
          'h-6 w-6 text-xxs': !size && state.user?.trigramme,
          'border border-white': multiple,
        },
        `bg-[${state.user?.color || '#5d669e'}]`,
      ]"
    >
      <figure v-if="state.user?.picture_url">
        <img
          class="h-full w-full rounded-full"
          :class="`${editable ? 'cursor-pointer' : 'cursor-default'}`"
          :src="state.user?.picture_url"
        />
      </figure>
      <span
        v-else
        class="cursor-default text-clip text-center align-middle text-white"
      >
        {{ userInitials?.toUpperCase() }}
      </span>
    </div>
    <span
      v-if="fullname"
      class="text-sm"
      :class="{
        'font-medium ': textBold,
        'text-sm text-primary': textPrimary,
        'text-sm text-[#5E5E5E]': textSecondary,
        'text-md text-gray-600': !textPrimary && !textSecondary,
      }"
    >
      {{ state.user?.first_name }} {{ state.user?.last_name }}
    </span>
  </div>
</template>
